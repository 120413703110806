import React from 'react'
import ReactMarkdown from "react-markdown"
import { graphql } from 'gatsby'
import TopMenu from '../components/topMenu'
import SEO from "../components/seo"
import Footer from "../components/footer"
import Title from '../components/titles/title'

const PriceTemplate = ({ data, pageContext }) => {
  const { frontmatter: content } = data.content
  const slug = content.slug;
  const settings = data.settings.childMdx.frontmatter;
  const categories = data.content.frontmatter.list;
  
  return (
    <section className={"page lang-" + pageContext.language}>
      <SEO
        title={content.title}
        description={content.description}
      />
      <div className="page__in container-1200">
        <TopMenu smallLogo={true} lang={content.language} languages={pageContext.languages} slug={slug} translation={pageContext.translation} settings={settings}></TopMenu>
        <div className="mb150">
          <div className="columns columns--vertical-tablet-to aic mb3">
            <div className="column column-40 posr mr2">
              <Title label1={content.title}></Title>
              {/* <h1 className="color-gold">{content.title}</h1> */}
            </div>
            <ReactMarkdown source={content.content} linkTarget="_blank" className="column column-60 text"/>

          </div>
          <div className="price container-900">
              {
                categories.map((category, c) => {
                  return(
                    <div key={c} className="price-category mb4">
                      <h3 className="price-title fwb color-gold">{category.title}</h3>
                      {
                        category.prices.map((row, i) => <div className="price-row" key={i}>
                          <div className="price-row-text df">
                            <span className="price-row-title">{row.title}</span>
                            <span className="price-row-space df-separator"></span>
                            <span className="price-row-price">{row.price}</span>
                          </div>
                          <div className="price-row-description mb1">{row.description}</div>
                        </div>)
                      }
                    </div>  
                  )           
                })
              }
            </div>

        </div>
      </div>
      <Footer lang={content.language} translation={pageContext.translation}></Footer>
    </section>
  )
}

export default PriceTemplate

export const indexQuery = graphql`
  query PriceById($id: String!) {
    content: mdx(
      id: { eq: $id }
    ) {
      frontmatter {
        slug
        language
        title
        description
        content
        list {
          title
          prices {
            title
            description
            price
            tags
           
          }  
        }
      }
    }
    settings: file(sourceInstanceName: {eq: "settings"}, name: {eq: "settings"}) {
      childMdx {
        frontmatter {
          detectLanguage
          address
          address2
          phone
          instagram
          facebook
          pinterest
          googlemaplink
          email
        }
      }
    }
    
  }
`